
.contact-questions {
    color: white;
    margin: 10% auto;
}
.contact-form {
    max-width: 100%;
    margin: auto;
}

@media screen and (min-width: 768px) {
    
    .contact-questions {
        margin: 5% auto; 
    }
    .contact-form {
        width: 50%
    }
}