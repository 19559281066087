.shop {
    margin: 5% auto;
    height: 50vh;
}
.merch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 15%;
    border-radius: 5%;
    max-width: max-content;
}

.merch-pic {
    max-width: 60%;
    box-shadow: 2px 2px 5px 2px rgba(20, 20, 0, 0.445);;
}

.merch-title, .merch-description {
    color: white;
    margin: 4% auto;
}

.merch-description {
    margin: 10px 15%
}

.buy {
    width: 25%;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.29);
    color: white;
    padding: 3%;
    outline: none;
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.283);
    border-radius: 3%;
    letter-spacing: 1px;
}

.coming-soon {

    color: white;
    margin: 10% auto;
    transform: translateY(200%);
    filter: drop-shadow(15px 6px 4px black);
}

@media screen and (min-width: 768px) {
    .shop {
        display:flex;
        justify-content: space-between;
        height: 80vh;
        margin: 5% auto;
        flex-direction: row;
        flex-flow: row wrap;
    }

    .merch-container {
        justify-content: space-around;
        max-width: 20%;
        margin: 2% auto;
    }

    .coming-soon {

        color: white;
        margin: 10% auto;
        transform: translateY(5%);
    }
}