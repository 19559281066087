.header-container {
    display: flex;
    justify-content: space-between;
}
.main-logo {
    margin: 50% 10% 0 10%;
    max-width: 60%;
    filter: drop-shadow(5px 2px 10px rgba(250, 246, 246, 0.249));

;
}

@media screen and (min-width: 768px) {

    .header-container {
        flex-direction: column;
        justify-content: space-between;
    }

    .main-logo {
        position: relative;
        margin: 4% auto;
        max-width: 20%;
        animation: fadelogo 1s forwards ease-in
    }

    @keyframes fadelogo {
        0% {
            opacity: 0.6;
        }
        20% {
            opacity: 1;
        }
        60% {
            opacity: 0.2
        }
        100% {
            opacity: 0.7;
        }

    }
}