.footer-right {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 5%;
  margin-top: 40px;
}

.footer-icon {
  max-width: 60px;
  box-sizing: initial;
}

.fb {
  height: 45px;
}

.ig {
  height: 40px;
  box-shadow: 2px 4px 2px 2px rgba(0, 0, 0, 0.164);
  border-radius: 16px;
}

.ra {
  height: 35px;
  margin-top: 5px;
  filter: drop-shadow(3px 4px 3px rgba(34, 34, 34, 0.87));
  background-color: rgba(255, 255, 255, 0.79);
  border-radius: 10%;
}

@media screen and (min-width: 768px) {

  .footer-right {
    margin-left: 60%;
    justify-content: space-evenly;
  }

  .footer-icon {
      
      
  }
}
