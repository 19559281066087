.contact-form {
    display: flex;
    flex-direction: column;
}

label {
    color: white;
    position: unset;
    width: max-content;


}

input, textarea, button {
    border-radius: 0;
    display: block;
    border: none;
    margin: 30px auto;
    padding: 0px;
    width: 50%;
    text-align: left;
    background-color: rgba(255, 255, 255, 0);
    color: white;
    height: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.301);
    outline: none;
    font-family: "IBM Plex Sans", sans-serif;
    resize: none;
    font-size: 13px

}

button {
    background-color: rgb(0, 0, 0);
    text-align: center;
    width: 50%;
}
textarea {
    padding-top: 7%;
    height: 70px;
    width: 60%;
    text-align: center;
    
}



@media screen and (min-width: 768px) {

    form {
        margin: 5% ;
        align-items: center;
    }
    input, textarea, button {
        display: inline;
        margin: 5% 10%;
        width: 80%
    }

    .curate-form {
        width: 40%;
    }

    button:hover {
        background-color: white;
        color: black;
        box-shadow: 3px 4px 3px 3px rgba(0, 0, 0, 0.66);
        font-weight: 700;
    }
}

