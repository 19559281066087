.events-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.event-nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav-past,
.nav-upcoming {
  margin: 0 auto;
}

.event-text,
.tickets {
  color: white;
  letter-spacing: 1px;
  margin: 2% 20%;
}

.flyer-container {
  display: flex;
  flex-direction: column;
}
.flyer {
  max-width: 80%;
  margin: 2% auto;
}
.event-featuring {
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 1px;
  margin: 30px;
  color: rgba(255, 251, 2, 0.822);
}
.event-supporting {
  margin-bottom: 50px;
}

.nodisplay {
  display: none;
  margin: 0 0;
  width: 0;
  position: unset;
}

.tickets {
  font-size: 24px;
  letter-spacing: 3px;
  padding: 3%;
  border: 1px dashed rgba(255, 255, 255, 0.479);
  text-decoration: none;
}

.short-line {
  width: 50%;
  height: 1px;
  background-color: grey;
  margin: 50px auto;
}

@media screen and (min-width: 768px) {
  * {
    margin: 0 0;
  }
  .event-nav-container {
    margin: 4% 20%;
  }
  .event-outer-container {
    display: flex;
    flex-direction: column;
  }
  .event {
    display: flex;
    width: 100%;
    margin: 2% auto;
    background-color: rgba(255, 255, 255, 0.064);;
  }

  .date-venue-container {
    margin: auto 5%;
  }
  .event-date,
  .event-venue {
    margin: 1%;
  }
  .flyer {
    max-width: 90%;
    margin: 3% 0 3% 10%;
    transition: transform 0.3s;
    opacity: 0.7;
  }
  .flyer:hover {
      transform: scale(1.2);
      opacity: 0.8;
  }

  .flyer-container {
    max-width: max-content;
  }
  .lineup {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-self: center;
    margin: auto;
    width: 100%;
    font-size: 18px;
  }
  .event-supporting {
    margin-bottom: 20%;
  }

  .tickets {
    display: flex;
    letter-spacing: 5px;
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 10%;
    padding: 2%;
    text-decoration: none;
    background-color: black;
    border: 1px solid rgba(255, 255, 255, 0.479);
    width: 40%;
    align-self: center;
    align-content: center;
    justify-content: center;
    
  }

  .tickets:hover {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.479);
    color: black;
  }
  .short-line {
    width: 80%;
    background-color: white;
  }
}
