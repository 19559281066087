.home-container {
    height: 50vh

}
a {
    color: white;
    text-decoration: none;
}
p[class= home-text] {
    color: white;
    line-height: 60px;
}

.fideles-modal {
    position: absolute;
    width: 80%;
    height: max-content;
    border: 2px ridge white;
    background-color: rgb(16, 16, 16);
    margin-left: 10%;
    animation: fadeInModal 3s;
    opacity: 0.8;
}
.close {
    cursor: pointer;
    position: absolute;
    top: -3%;
    right: 5%;
    background-color: black;
    font-family: Verdana, sans-serif;
    color: #ffffff;
    font-weight: bold;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 20px;
  }

  .modal-text {
      margin: 10%;
      color: white;
      line-height: 27px;
  }
 .none {
     display: none;
 }
@keyframes fadeInModal {

0% { opacity: 0;}
100% { opacity: 0.8;}

}

@media screen and (min-width: 768px) {


    
    .text-container {
        display: flex;
        justify-content: space-around;
        margin: 3% 6% 3% 10%;  
        height: 60vh; 

     }

    p[class= home-text] {
        color: white;
        width: max-content;
        margin-top: 5%;
        letter-spacing: 0.7em
    }

    .fideles-modal {
        margin: 1% 30%;
        width: 40%;
        
    }

    .close {
        
        top: -8%;
        right: -28%;
    }
    
}