.main {
    background-image: url('../../assets/bg1.jpg');
    /* background-color: rgb(29, 29, 29); */
    overflow-y: hidden;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.line {
    width: 20%;
    height: 1px;
    background-color: rgb(128, 128, 128);
    margin: 10% auto
}

@media screen and (min-width: 768px) {

    .line {
        margin: 1% auto
    }
}
